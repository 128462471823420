/***********
App
Componente principal
**************/

// Import react, router
import React from "react";
import { Routes, Route } from 'react-router-dom';
import { RequireAuth } from 'react-auth-kit'
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'react-auth-kit'
// Components
import Navbar from "./components/navBar";
import Login from "./routes/login";
import Principal from "./components/Principal";
import PrincipalOld from "./components/PrincipalOld";
import ErrorPage from "./routes/error-page";
    
// The App
const App = () => { 
    return (
        
        
    <AuthProvider
        authType={"localstorage"}
        authName={"_auth"}
        cookieDomain={window.location.hostname}
        cookieSecure={false}
    >
        <BrowserRouter>
            <Routes>                
                <Route path='/' element={
                    <RequireAuth loginPath="/login">
                        <Navbar />
                        <PrincipalOld />
                    </RequireAuth>
                } />                
                <Route path='/new' element={
                    <RequireAuth loginPath="/login">
                        <Navbar />
                        <Principal />
                    </RequireAuth>
                } />   
                <Route path='/login' element={<Login/>}/>
                <Route path="*" element={<ErrorPage />} />
            </Routes>
       
        </BrowserRouter>
    </AuthProvider>
    );
};
 
export default App;
