import React from 'react';
import { useSignIn } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css';

const url = "https://atlas.tbmed.com.br";

export default function Login() {
    const signIn = useSignIn()
    const [user, setUser] = React.useState('');
    const [pass, setPass] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const navigate = useNavigate();
    
    const onSubmit = (e) => {
        e.preventDefault();
        var sha1 = require('sha1');
        var formData = {user: user, pass: sha1(pass)};
        
        axios.post(url + '/server/login', formData)
            .then((res)=>{
                if(res.status === 200){
                    signIn({
                        token: res.data.token,
                        expiresIn: 360000,
                        tokenType: "Bearer",
                        authState: res.data.username
                    });
                    navigate("/");
                }
            })
            .catch(function (error) {
                setErrorMessage('Erro de autenticação!');
            })           
    }

    
      
    return(
        <div className="fullpage">
            <main className="form-signin">
                <form onSubmit={onSubmit}>
                    <img src="logo512.png" className="logo"/>
                    <h1 className="mb-2">MedMind</h1>
                    <p>IA para medicina</p>
                    {errorMessage && (
                        <p className="alert alert-danger" role="alert"> {errorMessage} </p>
                    )}
                    <div className="form-floating">
                        <input 
                            type={"text"} 
                            className="form-control" 
                            id="floatingInput" 
                            placeholder="usuario"
                            onChange={(e)=> {
                                     setUser(e.target.value)
                                     setErrorMessage()
                                     }}
                        />
                        <label htmlFor="floatingInput">Nome de usuário</label>
                    </div>
                    <div className="form-floating">
                        <input 
                            type={"password"} 
                            className="form-control" 
                            id="floatingPassword"
                            placeholder="Password"
                            onChange={(e)=> {
                                     setPass(e.target.value)
                                     setErrorMessage()
                                     }}
                        />
                        <label htmlFor="floatingPassword">Senha</label>
                    </div>
                    <button className="w-100 btn btn-lg btn-success" type="submit">Login</button>
                    <p className="mt-5 mb-3 text-muted">&copy; 2023 TBMed Serviços Médicos LTDA.</p>
                </form>
            </main>
        </div>
    )
}
