import axios from "axios";
import React, { useState, useRef, useEffect  } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

const openai_key = process.env.REACT_APP_OPENAI_KEY;
const azure_key = process.env.REACT_APP_AZURE_KEY;

const regras = [
  {
    nome : "Impres",
    ordem : "Escreva a  impressão clínica no modelo a seguir: <sexo>, <idade>, portadora de <comorbidades - sem as medicações>, interna por <motivo da internação>. <Exames de imagem, resumidos, sem exames laboratoriais>. <Quadro clínico atual>.",
    pretexto : "#Impressão clínica:",
    add : true
  },
  {
    nome : "Formatar",
    ordem : "Escreva o prontuário médico utilizando o seguinte modelo: `## EVOLUÇÃO CLÍNICA MÉDICA ##\n\n# Diagnósticos:\n<Escreva os diagnósticos atuais, um por linha>\n\n# Antecedentes:\n<Escreva as doenças prévias, uma por linha, usando abreviações. Caso não tenha nenhuma, escreva 'Nega comorbidades'>\n--> Uso prévio de:<Escreva as medicações que o paciente utilizava antes da internação, todas na mesma linha>\n--> Alergias: <Escreva as alergias do paciente. Caso não tenha nenhuma, escreva 'Nega'>\n\n # História clínica:\n<Escreva a história clínica da doença atual do paciente, iniciando com o motivo da internação>\n\n# Evolução:\n<Escreva o quadro atual do paciente>\n\n# Exame físico:\n<Escreva o exame físico do paciente, organizando com cada tópico em uma linha: Neuro, ACV, AR, Abdomen, MMII>\n\n# Em uso de:\n- ATB:<Escreva os antibióticos que o paciente está utilizando, caso não esteja usando, escreva 'Sem ATB'>\n- Profilaxia de TEV:<Com base nas informações do paciente, calcule o risco de TEV. Caso baixo risco, escreva 'Sem, baixo risco', caso alto risco, observe a função renal, caso função renal normal escreva 'Clexane', caso função renal alterada escreva 'Heparina'>\n\n# Controles:\n\n\n # Exames:\n<Escreva os exames de imagem fornecidos, um por linha, com data e resumo do laudo>\n<Escreva os exames laboratoriais fornecidos, na mesma linha, com data no início e abreviando>\n\n# Plano terapêutico:\n<Escreva o plano terapêutico do paciente>\n\n# Metas diárias:\n<Escreva as metas diárias, uma por linha>\n\n# Previsão de alta em:",
    pretexto : "",
    add: false
  },
  {
    nome : "HD",
    ordem : "Cite as hipóteses diagnósticas pertinentes ao caso, uma por linha, sem explicações ou observações.",
    pretexto : "#HD:",
    add: true
  },
  { 
    nome : "Condutas",
    ordem : "Descreva as condutas relevantes ao caso, uma por linha.",
    pretexto : "# Condutas:",
    add: true
  },
  {
    nome : "Receita",
    ordem : "Organize as medicações apresentadas em forma de receita médica, no seguinte formato: '<número, iniciando em 1>.<nome genérico da medicação> <apresentação em mg> ------------- contínuo \n Tomar <x> <cp ou cps> de <x/x h ou 1x/dia>' Observações: substitua os nomes das medicações por nomes genéricos. Não utilize 'sódico', 'cloridrato'. Não utilize o termo 'VO'. Apenas ajuste as medicações já existentes, não acrescente novas medicações. ",
    pretexto : "",
    add: false
  }
];


function PrincipalOld() {
    const [texto, setTexto] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedText, setSelectedText] = useState("");
    const [showDialog, setShowDialog] = useState(false);
    const [dialogText, setDialogText] = useState("");
    const dialogRef = useRef(null);
    const quillRef = useRef(null);
    
    const handleDialogTextChange = (event) => {
        setDialogText(event.target.value);
    };

    useEffect(() => {
        if (dialogRef.current) {
            dialogRef.current.focus();
        }
    }, [showDialog]);

  async function processaTexto(regra) {
    setIsLoading(true);
    try {
      const response = await axios.post(
        //"https://api.openai.com/v1/chat/completions",
        "https://medmind.openai.azure.com/openai/deployments/gpt-35/chat/completions?api-version=2023-03-15-preview",
        {
          //model: "gpt-3.5-turbo",
          "messages": [
              {
              "role": "user",
              "content": "Utilizando as informações do paciente fornecidas, atue como um auxiliar na organização de prontuários médicos. Utilize apenas as informações que forem dadas, não crie dados novos. " + regra.ordem + " Informações do paciente: ```" + texto + "```"
              }
          ],          
          "max_tokens": 1024,
          "n": 1,
        },
        {
          "headers": {
            //'Authorization': 'Bearer ' + openai_key, 
            "api-key": azure_key,
            "Content-Type": "application/json",
          },
        }
      );
      if (regra.add) {
        const newtext = texto + "\n\n" + regra.pretexto + " " + response.data.choices[0].message.content
        setTexto(newtext);
      } else {
        setTexto(response.data.choices[0].message.content);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }
    
    function handleAjustar() {
        const selection = window.getSelection();
        if (!selection.toString()) return;
        setSelectedText(selection.toString());
        setShowDialog(true); 
    }

    async function handleOk () {
        setShowDialog(false);
        setIsLoading(true); 
        try {
          const response = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
              model: "gpt-3.5-turbo",
              messages: [
                  {
                  "role": "system",
                  "content": dialogText
                  },
                  {
                  "role": "user",
                  "content": selectedText
                  }
              ],          
              max_tokens: 1024,
              n: 1,
            },
            {
              headers: {
                'Authorization': 'Bearer ' + openai_key, 
                "Content-Type": "application/json",
              },
            }
          );

          const generatedText = response.data.choices[0].message.content;
          const newText = texto.replace(selectedText, generatedText);
          setTexto(newText);
          setDialogText("");

        } catch (error) {
          console.error(error);
        }
        setIsLoading(false);
    }


  const handleCopyToClipboard = () => {

    navigator.clipboard.writeText(texto);
      
      
  }
  const handleTextoChange = (event) => {
    setTexto(event.target.value);
  };
    
  return (
    <Container fluid className="mt-2">
      <Row>
        <Col lg={9}>
          <Form.Control className="text_principal" as="textarea" rows={25} onChange={handleTextoChange} value={texto} disabled={isLoading}/>
      
           {isLoading && (
            <div className="loading">
              <img src="loading.svg" alt="loading" />
            </div>
          )}
        </Col>
        <Col lg={3}>
          <Row className="mt-2">
            <Col>
              <Button variant="warning" block onClick={handleCopyToClipboard}>
                Copiar
              </Button>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Button variant="danger" block onClick={() => setTexto("")}>
                Excluir
              </Button>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Button variant="success" block onClick={() => processaTexto(regras.find(regra => regra.nome === "Formatar"))}>
                Formatar
              </Button>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Button variant="success" block onClick={() => processaTexto(regras.find(regra => regra.nome === "Impres"))}>
                Impressão clínica
              </Button>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Button variant="success" block onClick={() => processaTexto(regras.find(regra => regra.nome === "HD"))}>
                Hipóteses diagnósticas
              </Button>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Button variant="success" block onClick={() => processaTexto(regras.find(regra => regra.nome === "Condutas"))}>
                Condutas
              </Button>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Button variant="success" block onClick={() => processaTexto(regras.find(regra => regra.nome === "Receita"))}>
                Receita
              </Button>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col>
              <Button variant="success" block onClick={handleAjustar}>
                Ajustar
              </Button>

            {showDialog && (
              <div className="dialog">
                <label htmlFor="ordem">Ordem:</label>
                <input type="text" id="ordem" value={dialogText} onChange={handleDialogTextChange} ref={dialogRef}/>
                <Button onClick={handleOk}>OK</Button>
              </div>
            )}

            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <footer>
          </footer>
        </Col>
      </Row>
    </Container>
  );
}

export default PrincipalOld;